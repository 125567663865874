<template>
  <div
    class="row justify-content-end"
    style="padding-right: 15px; padding-bottom: 24px"
  >
    <a-month-picker
      v-model:value="month"
      placeholder="Seleccionar mes"
      @change="onChangeMonth"
    />
  </div>
  <a-table
    :columns="columns"
    :data-source="data()"
    @change="onChange"
    :scroll="{ x: 1300 }"
  >
    <template #products="{ record }">
      <li v-for="product in record.products" :key="product.product.name">
        {{ product.product.name }}
        (x{{ product.cant }}) (${{ product.product.price }})
      </li>
    </template>
    <template #commission="{ record }">
      {{ record.ally_ref?.commission ?? record.delivery_ref?.commission ?? 0 }}
    </template>
    <template #price_service="{ record }">
      {{ record.price_service?.toFixed(2) }}
    </template>
    <template #delivery_price="{ record }">
      {{ record.delivery_price?.toFixed(2) }}
    </template>
    <template #total_price="{ record }">
      {{
        (
          (record.products_price ?? 0) +
          (record.delivery_price ?? 0) +
          (record.price_service ?? 0)
        ).toFixed(2)
      }}
    </template>
  </a-table>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import moment from "moment";
import { getHistory } from "../../../router/auth_guard";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "Fecha",
    dataIndex: "day",
    defaultSortOrder: "descend",
    sorter: (a, b) => (a.day > b.day ? 1 : a.day < b.day ? -1 : 0),
  },
  {
    title: "Aliado",
    dataIndex: "ally_ref.email",
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      a.ally_ref.email > b.ally_ref.email
        ? 1
        : a.ally_ref.email < b.ally_ref.email
        ? -1
        : 0,
  },
  {
    title: "Motorizado",
    dataIndex: "delivery_ref.email",
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      a.delivery_ref.email > b.delivery_ref.email
        ? 1
        : a.delivery_ref.email < b.delivery_ref.email
        ? -1
        : 0,
  },
  {
    title: "Cliente",
    dataIndex: "client_ref.email",
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      a.client_ref.email > b.client_ref.email
        ? 1
        : a.client_ref.email < b.client_ref.email
        ? -1
        : 0,
  },
  {
    title: "Productos",
    // dataIndex: "order_data",
    slots: { customRender: "products" },
  },
  {
    title: "Precio productos",
    dataIndex: "products_price",
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      a.products_price > b.products_price
        ? 1
        : a.products_price < b.products_price
        ? -1
        : 0,
  },
  // Será la sumatoria de todas las comisiones
  {
    title: "Precio comisión",
    slots: { customRender: "commission" },
  },
  {
    title: "Precio envio",
    slots: { customRender: "delivery_price" },
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      a.delivery_price > b.delivery_price
        ? 1
        : a.delivery_price < b.delivery_price
        ? -1
        : 0,
  },
  {
    title: "Precio I.V.A.",
    slots: { customRender: "price_service" },
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      a.price_service > b.price_service
        ? 1
        : a.price_service < b.price_service
        ? -1
        : 0,
  },
  {
    title: "Precio total",
    slots: { customRender: "total_price" },
  },
];

export default {
  setup() {
    const store = useStore();
    const onChange = (pagination, filters, sorter) => {
      console.log("params", pagination, filters, sorter);
    };
    const onChangeMonth = async (date) => {
      await store.dispatch("getHistory", date);
    };
    const data = () => store.getters.ordersFromHistory;
    return {
      data,
      columns,
      month: ref(moment()),
      onChangeMonth,
      onChange,
    };
  },
  beforeRouteEnter: getHistory,
};
</script>

<style>
.ant-table-body {
  overflow-x: auto !important;
}
</style>